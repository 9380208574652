<template>
  <div v-if="showMessage">
    <v-alert :value="showMessage" type="info" class="my-2 mx-4">{{ $t('upcoming-maintenance-message') }}</v-alert>
  </div>
</template>
<script>
import Status from '@/services/status'

export default {
  data() {
    return {
      showUpcomingMaintenanceMessage: false
    }
  },
  mounted() {
    this.showUpcomingMaintenanceMessage = Status.getShowUpcomingMaintenanceMessage()

    const vm = this
    this.interval = setInterval(function () {
      vm.updateShowMessage()
    }, 1000)
  },
  methods: {
    updateShowMessage: function () {
      this.showUpcomingMaintenanceMessage = Status.getShowUpcomingMaintenanceMessage()
    }
  },
  computed: {
    showMessage() {
      return this.showUpcomingMaintenanceMessage
    }
  }
}
</script>
